import Button from '../Button';
import styels from './index.module.scss';
import clsx from 'clsx';
import ShareIcon from '../../assets/ShareIcon';
import VkLogo from '../../assets/VkLogo';

interface IShareButton {
    shareListClasses?: string;
    shareIconOnClick?: () => void;
    shareVkOnClick?: () => void;
    className?: string;
    type?: 'orange-gradient' | 'primary';
    hreafArrt?: Record<string, string>;
}

const ShareButton: React.FC<IShareButton> = ({
    shareListClasses = '',
    shareIconOnClick,
    shareVkOnClick,
    className = '',
    type = 'primary',
    hreafArrt = {},
}) => {
    return (
        <div className={styels['share-btn__container']} onClick={(e) => e.stopPropagation()}>
            <Button
                text="Поделиться"
                type="button"
                variant={type}
                className={clsx(styels['share-btn'], className)}
            />
            <ul className={clsx(shareListClasses, styels['share-btn__icons-list'])}>
                <li>
                    <a href="#" {...hreafArrt}>
                        <ShareIcon
                            className={styels['share-btn__icon']}
                            onClick={shareIconOnClick}
                        />
                    </a>
                </li>
                <li>
                    <VkLogo className={styels['share-btn__icon']} onClick={shareVkOnClick} />
                </li>
            </ul>
        </div>
    );
};

export default ShareButton;
