import styles from './index.module.scss';
import internetWithoutDangerLogo from './assets/internetWithoutDangerLogo.webp';
import ncptiLogo from './assets/ncptiLogo.webp';
import Logo from '../../../../shared/ui/Logo';
import weTogethere from './assets/weTogethere.png';

const FooterLogoContainer = () => {
    return (
        <div className={styles['footer__logo-container']}>
            <Logo className={styles['footer__logo1']} />
            <a href="/">
                <img
                    className={styles['footer__logo1']}
                    src={internetWithoutDangerLogo}
                    alt="логотип АНО 'Интернет без угроз'"
                />
            </a>
            <a href="/">
                <img
                    className={styles['footer__logo3']}
                    src={ncptiLogo}
                    alt="логотип АНО 'Интернет без угроз'"
                />
            </a>

            <a href="/">
                <img
                    className={styles['footer__logo4']}
                    src={weTogethere}
                    alt="логотип АНО 'Интернет без угроз'"
                />
            </a>
        </div>
    );
};

export default FooterLogoContainer;
